exports.components = {
  "component---src-containers-blog-post-js": () => import("./../../../src/containers/Blog/post.js" /* webpackChunkName: "component---src-containers-blog-post-js" */),
  "component---src-containers-landings-landing-js": () => import("./../../../src/containers/Landings/landing.js" /* webpackChunkName: "component---src-containers-landings-landing-js" */),
  "component---src-containers-projects-project-js": () => import("./../../../src/containers/Projects/project.js" /* webpackChunkName: "component---src-containers-projects-project-js" */),
  "component---src-containers-toolkits-toolkit-js": () => import("./../../../src/containers/Toolkits/toolkit.js" /* webpackChunkName: "component---src-containers-toolkits-toolkit-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-brand-js": () => import("./../../../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-digital-js": () => import("./../../../src/pages/digital.js" /* webpackChunkName: "component---src-pages-digital-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrial-js": () => import("./../../../src/pages/industrial.js" /* webpackChunkName: "component---src-pages-industrial-js" */),
  "component---src-pages-privacycookies-js": () => import("./../../../src/pages/privacycookies.js" /* webpackChunkName: "component---src-pages-privacycookies-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

